interface IUseImageModal {
    open: () => void;
}

export const useImageModal = (modal: HTMLDialogElement): IUseImageModal => {
    const closeButton = modal.querySelector('.image-modal__close-button') as HTMLButtonElement;
    
    const open = (): void => {
        modal.showModal();
        modal.focus();

        const modalImage = modal.querySelector('.image-modal__image') as HTMLImageElement;
        const modalCaption: HTMLElement | null = modal.querySelector('.image-modal__caption');

        if (modalCaption) {
            modalImage.style.maxHeight = `calc(100% - ${modalCaption.offsetHeight}px)`;
        }

        document.body.classList.add('has-full-screen-modal');
    }
    
    const close = (): void => {
        document.body.classList.remove('has-full-screen-modal');
    }
    
    const initialize = (): void => {
        closeButton.addEventListener('click', (): void => {
            modal.close();
        });

        modal.addEventListener('click', (event: MouseEvent): void => {
            if (event.target instanceof Element && event.target === modal) {
                modal.close();
            }
        });

        modal.addEventListener('close', (): void => {
            close();
        });
    }
    
    initialize();
    
    return {
        open
    }
};
