import { useImageModal } from "@features/Shared/Features/ImageModal/Scripts/imageModal";

export const useImageBlock = (blockContentElement: HTMLDivElement): void => {
    const imageLink = blockContentElement.querySelector('.image-block__image-link') as HTMLAnchorElement;
    const modalElement = blockContentElement.querySelector('.image-modal') as HTMLDialogElement;
    const imageModal = useImageModal(modalElement);
    
    const initialize = (): void => {
        imageLink.addEventListener('click', (event: MouseEvent) => {
            event.preventDefault();
            imageModal.open();
        });
    }

    initialize();
};

document.addEventListener('DOMContentLoaded', () => {
    const imageBlockContentElements = document.querySelectorAll('.image-block__content--has-lightbox') as NodeListOf<HTMLDivElement>;

    if (imageBlockContentElements.length > 0) {
        imageBlockContentElements.forEach((blockContentElement) => {
            useImageBlock(blockContentElement);
        })
    }
});
